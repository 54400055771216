<template>
    <div class="h-full pb-10">
        <div class="flex gap-2 items-center">
            <h3 class="text-xl font-bold">{{$route.name}}</h3>
        </div>

        <div class="content bg-white shadow rounded mt-2 p-3 h-full">
            <a-input-search placeholder="Search SKU..." @search="onSearch" class="mb-3" :loading="loading"/>
            <a-table :columns="columns" :data-source="orders" :row-key="order => order.OrderId" bordered :loading="loading" :pagination="false">
                <template #name="{record}">
                    <template v-if="record.CustomerInfo.ChannelBuyerName !== ''">{{record.CustomerInfo.ChannelBuyerName}}</template>
                    <template v-if="record.CustomerInfo.ChannelBuyerName == '' && record.FolderName.includes('Fabrication')">Fabrication</template>
                </template>
                <template #date="{record}"> {{formatDate(record.GeneralInfo.ReceivedDate)}} </template>
                <template #folderName="{record}"> {{formatArray(record.FolderName)}} </template>
                <template #urgent="{record}">
                    <a-checkbox v-model:checked="record.urgent" @change="updateUrgent($event, record)"></a-checkbox>
                </template>

                <template #expandedRowRender="{record}">
                    <div style="margin: 0" :class="{'no-missing-items': record.MissingItems.length === 0}">
                        <h6>Missing Items</h6>
                        <a-tag v-for="sku in record.MissingItems" :key="sku" class="mb-1">{{sku}}</a-tag>
                    </div>
                </template>
            </a-table>
        </div>
    </div>
</template>

<script>
import {defineComponent} from "vue";
import moment from 'moment';
import _ from 'lodash';

let apiUrl = "https://api.darkside-developments.com";
if(location.host.includes("localhost:")) {
    apiUrl = "https://darkside_api.test";
}

export default defineComponent({
    name: "SKULookup",
    data() {
        return {
            loading: false,
            columns: [
                {
                    title: 'ID',
                    dataIndex: 'NumOrderId',
                    sorter: (a, b) => a.NumOrderId - b.NumOrderId,
                },
                {
                    title: 'Name',
                    dataIndex: 'name',
                    sorter: (a, b) => a.name.localeCompare(b.name),
                },
                {
                    title: 'Date Created',
                    dataIndex: 'GeneralInfo.ReceivedDate',
                    slots: { customRender: 'date' },
                    sorter: (a, b) => new moment(a.GeneralInfo.ReceivedDate).format('YYYYMMDD') - new moment(b.GeneralInfo.ReceivedDate).format('YYYYMMDD'),
                    defaultSortOrder: 'asc'
                },
                {
                    title: 'Bigcommerce Reference',
                    dataIndex: 'GeneralInfo.SecondaryReference',
                    sorter: (a, b) => a.GeneralInfo.SecondaryReference.localeCompare(b.GeneralInfo.SecondaryReference),
                },
                {
                    title: 'Folder Name',
                    dataIndex: 'FolderName',
                    slots: { customRender: 'folderName' },
                    sorter: (a, b) => a.FolderName.length - b.FolderName.length,
                },
                {
                    title: 'Urgent',
                    slots: { customRender: 'urgent' },
                    sorter: (a, b) => b.urgent - a.urgent,
                    defaultSortOrder: 'asc'
                },
            ],
            orders: []
        }
    },
    mounted() {
    },
    methods: {
        onSearch(sku) {
            if(sku !== "") {
                this.loading = true;
                this.$http.post(apiUrl + '/api/dispatch/order-from-sku',{sku: sku}).then((resp) => {
                    if(resp.data.success) {
                        console.log(resp.data.data)
                        resp.data.data.forEach(element => {
                            if(element.CustomerInfo.ChannelBuyerName !== '') {
                                element.name = element.CustomerInfo.ChannelBuyerName
                            } else if(element.CustomerInfo.ChannelBuyerName == '' && element.FolderName.includes('Fabrication')){
                                element.name = "Fabrication";
                            }
                        });
                        this.orders = resp.data.data;
                        setTimeout(() => {
                            this.orders.forEach(element => {
                                if(element.MissingItems.length === 0) {
                                    var rowKey = element.OrderId;
                                    var row = document.querySelectorAll('[data-row-key="'+rowKey+'"]')[0].querySelectorAll('[role="button"]');
                                    console.log(row[0].style.display = "none");
                                }
                            });
                        }, 200);
                    } else {
                        this.orders = [];
                        alert('No orders found for SKU')
                    }
                    this.loading = false;
                }).catch(err => {
                    this.orders = [];
                    console.log(err)
                    this.loading = false;
                })
            }
        },
        formatArray(arr) {
            return _.join(arr, ', ');
        },
        countItems(items) {
            var count = 0;
            items.forEach((element) => {
                count++;
                element.CompositeSubItems.forEach(() => {
                    count++
                });
            });
            return count;
        },
        countItemsNotAvailable(items) {
            var count = 0;
            items.forEach(element => {
                if(element.StockLevel) {
                    var found = element.StockLevel.StockLevels.find(el => el.Location.LocationName == 'Default');
                    if(found) {
                        if(found.Available <= 0) {
                            count++;
                        }
                    }
                }
                element.CompositeSubItems.forEach((el2) => {
                    if(el2.StockLevel) {
                        var found2 = el2.StockLevel.StockLevels.find(el => el.Location.LocationName == 'Default');
                        if(found2) {
                            if(found2.Available <= 0) {
                                count++;
                            }
                        }
                    }
                });
            });
            return count;
        },
        formatDate(val) {
            return moment(val).format('DD-MM-YYYY HH:mm');
        },
        updateUrgent(e, record) {
            const value = e.target.checked
            this.$http.post(apiUrl + '/api/dispatch/orders/urgent',{
                value: value,
                id: record.OrderId
            }).then((resp) => {
                console.log(resp.data)
            }).catch(err => {
                console.log(err)
            })
        }
    }
});
</script>

<style scoped>

</style>
